import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/custom/css/popup.css';
// import useStateRef from 'react-usestateref';
import useStateRef from "react-usestateref";
const Popup = ({ onClose, offerData }) => {
  const [email, setEmail] = useState('');
  const [offer, setOffer] = useState({});
  const [newOffer, setNewOffer] = useState([]);
  const fromDate = newOffer.from_date?.slice(0, 10);
  const toDate = newOffer.to_date?.slice(0, 10);
  // const [datas, setData, Refdatas] = useStateRef([]);
  const [datas, setData, Refdatas_Ref] = useStateRef([]);
  const [Count, setCount, Count_Ref] = useStateRef(0);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      // const response = await axios.get('https://gateway.velancabs.com//admin/offer/latest', {
      const response = await axios.get('http://192.168.0.114:8000/admin/offer/latest', {
        headers: {
          'accept': 'application/json',
        },
      });
      console.log('rrrrrrr', response)
      setData(response.data)
      setTimeout(() => {
        const intervalId = setInterval(test, 5000);
      }, 1000);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const test = () => {
    var len = Refdatas_Ref.current.length;
    if (len >= Count_Ref.current) {
      if (len == Count_Ref.current) {
        setCount(0)
        setNewOffer(Refdatas_Ref.current[0])
        setCount(Count_Ref.current + 1)
      } else {
        setNewOffer(Refdatas_Ref.current[Count_Ref.current])
        setCount(Count_Ref.current + 1)
      }
    } else {
      setCount(0)
      setNewOffer(Refdatas_Ref.current[0])
      setCount(Count_Ref.current + 1)
    }

  }


  if (fromDate && toDate) {
    const formattedFromDate = fromDate.split("-").reverse().join("-");
    const formattedToDate = toDate.split("-").reverse().join("-");

    return (
      <div className="popup">
        <div className="popup-content">
          <h1 className='mobilView1' style={{ marginTop: '20px' }}>{newOffer.offer_precentage}% OFF   </h1>
          <h2 className='mobileView2' style={{ marginTop: '50px' }}> {newOffer.offer_tittle
          } </h2>
          <h2 className='mobileView3' style={{ marginTop: '20px' }}> {newOffer.pickup_location} to {newOffer.drop_location} </h2>
          <h3 className='mobileView4' style={{ color: 'white' }}>
            {formattedFromDate} to {formattedToDate} </h3>
          <a className="nope-email nothanks" onClick={onClose}>
            No, Thanks
          </a>
          <p className='paragraph' style={{ color: 'white' }}>
            We provide return trip also along with one way if needed. We are the leading outdoor taxi service in Coimbatore by providing the best service at the lowest fare. For any queries, you may contact our customer service.
          </p>
          <button className="popup-close" style={{ marginTop: '-6px' }} onClick={onClose}>
            <h4 style={{ marginTop: '-10px' }}>X</h4>
          </button>
        </div>
        <style>
          {`
    @media (max-width: 767px) {
        .mobileView2 {
font-size:20px !important;
        }
        .mobileView3{
          margin-top:20px !important;
          font-size:20px !important;
        }
        .nothanks{
          margin-top:12px !important;
          font-size:17px !important;
          color:#00f390 !important;
        }
        .paragraph{
          margin-top:20px !important;
          font-size:10px !important;
          color:white !important; 
        }
        .mobileView4{
          margin-top:20px !important;
          font-size:18px !important;
        }    
.welcome-area .welcome-text h1 {
  color: #fff;
  font-family: 'Poppins';
  font-size: 36px;
  line-height: 163px !important;
  font-weight: 700;
  margin-left: 35px;
  margin-top: -10px;
}
  `}
        </style>
      </div>
    );
  } else {
    console.error("Error: fromDate or toDate is undefined.");
  }

};

export default Popup;
