
import React, { useState } from 'react'
import useStateRef from 'react-usestateref';
import MainLayout from '../layout/mainLayout';
import Button from '@mui/material/Button';
import '../assets/custom/css/cabsSearch.css'
import suv from '../assets/images/carpng/desire.png'
import suv1 from '../assets/images/carpng/taximo-etios.png'
import suv2 from '../assets/images/carpng/taximo-innova_hycross.png'
import suv3 from '../assets/images/carpng/innovaCr.webp'
import suv4 from '../assets/images/carpng/ionova.png'
import { useNavigate } from 'react-router-dom'
const CabsSearch = () => {
  const navigate = useNavigate();
  const SubmitCall = () => {
    console.log("cliicc");
    window.open('https://api.whatsapp.com/send?phone=9629562666', '_blank');
    // try {
    //   navigate("/booking")
    // } catch (e) {
    //   // console.log(e);
    // }
  }
  return (
    <>
      <MainLayout>
        <section className="welcome-area3">
          <div className="overlay"></div>
          <div className="container-fluid">
            <div className="row row-we1">
              <div className="col-md-6">
                <div className="welcome-text" style={{ marginTop: '10%' }} >
                  <h1 style={{ color: 'white', WebkitTextStroke: '1px #F8F8F8', textShadow: '0px 1px 4px #23430C', fontSize: '60px' }}>
                    <span>
                      Select Type of
                    </span>
                    <br />
                    Ride
                    <br />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-ride-main pt40 pb20">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className='container'>
                  <section className="wh-work pt60 pb60">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-lg-4 col-md-4 col-6 col-6 price1 ">
                          <div className="pricing-table gprice-single">
                            <div className="content">
                              <div className="price">
                                <img src={suv}></img>
                              </div>
                              <ul>
                                <li className="title"><h1> Economy</h1></li>
                                <li>Minimum Distance: <b>130 kms</b> </li>
                                <li>Extra Toll at actuals + Extra Permit charges applicable.</li>
                                <li><h1>Rs.14/kms</h1></li>
                              </ul>
                              <div className="sign-up">
                                <Button className="btn bordered radius"
                                  onClick={() => { SubmitCall() }}>Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6  price1">
                          <div className="pricing-table gprice-single">
                            <div className="content">
                              <div className="price">
                                <img src={suv1}></img>
                              </div>
                              <ul>
                                <li className="title"><h1> Prime</h1></li>
                                <li>Minimum Distance: <b>130 kms</b> </li>
                                <li>Extra Toll at actuals + Extra Permit charges applicable.</li>
                                <li><h1>Rs.15/kms</h1></li>
                              </ul>
                              <div className="sign-up">
                                <Button onClick={() => { SubmitCall() }} className="btn bordered radius">Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6 price1 ">
                          <div className="pricing-table gprice-single">
                            <div className="content">
                              <div className="price">
                                <img src={suv4}></img>
                              </div>
                              <ul>
                                <li className="title"><h1> Any SUV</h1></li>
                                <li>Minimum Distance: <b>130 kms</b> </li>
                                <li>Extra Toll at actuals + Extra Permit charges applicable.</li>
                                <li><h1>Rs.20/kms</h1></li>
                              </ul>
                              <div className="sign-up">
                                <Button onClick={() => { SubmitCall() }} className="btn bordered radius">Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 col-6 price1 " style={{marginTop:100}}>
                          <div className="pricing-table gprice-single">
                            <div className="content">
                              <div className="price mobiletop">
                                <img src={suv2}></img>
                              </div>
                              <ul>
                                <li className="title"><h1> Innova</h1></li>
                                <li>Minimum Distance: <b>130 kms</b> </li>
                                <li>Extra Toll at actuals + Extra Permit charges applicable.</li>
                                <li><h1>Rs.22/kms</h1></li>
                              </ul>
                              <div className="sign-up">
                                <Button onClick={() => { SubmitCall() }} className="btn bordered radius">Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 col-6 price1 " style={{marginTop:100}}>
                          <div className="pricing-table gprice-single">
                            <div className="content">
                              <div className="price1">
                                <img src={suv3}></img>
                              </div>
                              <ul>
                                <li className="title"><h1> Innova Crysta</h1></li>
                                <li>Minimum Distance: <b>130 kms</b> </li>
                                <li>Extra Toll at actuals + Extra Permit charges applicable.</li>
                                <li><h1>Rs.28/kms</h1></li>
                              </ul>
                              <div className="sign-up">
                                <Button onClick={() => { SubmitCall() }} className="btn bordered radius">Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout >
    </>
  );
}
export default CabsSearch;
