import React from 'react';
import '../assets/custom/css/FooterBar.css';
import fbicon1 from '../assets/images/brands/08.png';
import twicon1 from '../assets/images/brands/09.jpg';
import igicon1 from '../assets/images/brands/10.png';
import google1 from '../assets/images/brands/11.png';
import droppin1 from '../assets/images/icons/icons8-location-100.png';
import gpay from '../assets/images/brands/icons8-google-pay-is-the-fast,-simple-way-to-pay-online,-in-stores-and-more.-48.png';
import bhim from '../assets/images/brands/icons8-bhim-upi-100.png';
import visa from '../assets/images/brands/icons8-visa-100.png';
import mastercard from '../assets/images/brands/icons8-mastercard-100.png';
import applogopng from '../assets/images/customimg/appLogo.png';
import calllogo from '../assets/images/brands/icons8-call-90.png';
import gmail from '../assets/images/brands/icons8-gmail-100.png';
import back1 from '../assets/images/back.png'
import { useNavigate } from 'react-router-dom'
function Privacy() {
    const navigate = useNavigate();

    const back = () => {
       
        try {
         
            navigate("/")
            //console.log("valid");
         
        } catch (e) {
          
        }
      }
    
    return (
        <div style={{padding:"50px"}}>
 <a onClick={() => { back() }} ><i > <img style={{ height: '50px' }} src={back1} alt="" /></i></a>
<h1 style={{display:"flex",justifyContent:"center",marginTop:"-50px"}} > Privacy Policy</h1>
            <h3>

           
           <span style={{marginLeft:"20px"}}>
           At Velan Cabs, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to safeguard it.
            </span>  
            </h3>
            <ol>
                {/* <li>
                    <h4>Information We Collect</h4>
                    <p>
                        We may collect the following types of personal information when you use our website or mobile application:
                    </p>
                    <ul>
                        <li>Contact Information: such as your name, email address, phone number, and postal address.</li>
                        <li>Payment Information: such as credit card details or other payment information.</li>
                        <li>Trip Information: such as pickup and drop-off locations, travel dates, and preferences.</li>
                        <li>Device Information: such as IP address, browser type, and device identifiers.</li>
                        <li>Usage Information: such as pages visited, features used, and interactions with our services.</li>
                    </ul>
                </li>
                <li>
                    <h4>How We Use Your Information</h4>
                    <p>
                        We may use your personal information for the following purposes:
                    </p>
                    <ul>
                        <li>Providing Services: to facilitate cab bookings, manage reservations, and process payments.</li>
                        <li>Improving Services: to analyze usage trends, enhance user experience, and develop new features.</li>
                        <li>Communication: to send transactional emails, updates, and promotional offers.</li>
                        <li>Compliance: to comply with legal obligations and enforce our Terms of Service.</li>
                    </ul>
                </li>
                <li>
                    <h4>Data Security</h4>
                    <p>
                        We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </p>
                </li>
                <li>
                    <h4>Data Retention</h4>
                    <p>
                        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                    </p>
                </li>
                <li>
                    <h4>Third-Party Services</h4>
                    <p>
                        We may use third-party service providers to assist us in delivering our services and analyzing usage trends. These service providers may have access to your personal information but are contractually obligated to use it solely for the purpose of providing the requested services.
                    </p>
                </li>
                <li>
                    <h4>Your Rights</h4>
                    <p>
                        You have the right to access, update, or delete your personal information. You may also opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.
                    </p>
                </li>
                <li>
                    <h4>Changes to this Privacy Policy</h4>
                    <p>
                        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website or mobile application.
                    </p>
                </li>
                <li>
                    <h4>Contact Us</h4>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:cabsvelan@gmail.com">contact@velancabs.com</a>.
                    </p>
                </li> */}
    <p>At Velan Cabs, safeguarding your privacy is paramount to us. This Privacy Policy elucidates how we gather, utilize, share, and protect your personal data when you interact with our website or mobile application.</p>
    
    <h2>Information We Collect</h2>
    <ul>
        <li>1. <strong>Contact Information:</strong> Including your name, email address, phone number, and postal address.</li>
        <li>2. <strong>Payment Information:</strong> Such as credit card details or other payment information.</li>
        <li>3. <strong>Trip Information:</strong> Comprising pickup and drop-off locations, travel dates, and preferences.</li>
    </ul>
    
    <h2>How We Use Your Information</h2>
    <ul>
        <li>1. <strong>Providing Services:</strong> Facilitating cab bookings, managing reservations, and processing payments.</li>
        <li>2. <strong>Improving Services:</strong> Analyzing usage trends, enhancing user experience, and developing new features.</li>
        <li>3. <strong>Communication:</strong> Sending transactional emails, updates, and promotional offers.</li>
        <li>4. <strong>Compliance:</strong> Ensuring adherence to legal obligations and enforcing our Terms of Service.</li>
    </ul>
    
    <h2>Access</h2>
    <p>We ensure that only authorized personnel have access to your personal data. Access controls and protocols are implemented to safeguard against unauthorized access or misuse.</p>
    
    <h2>Sharing</h2>
    <p>We may share your personal information with trusted third-party service providers to assist us in delivering our services and analyzing usage trends. These providers are contractually obligated to use your information solely for providing the requested services and adhere to strict confidentiality agreements.</p>
    
    <h2>Data Security</h2>
    <p>We implement robust technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Despite our efforts, no method of transmission over the internet or electronic storage is entirely secure, and therefore, we cannot guarantee absolute security.</p>
    
    <h2>Data Retention</h2>
    <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
    
    <h2>Your Rights</h2>
    <ul>
        <li>1. You have the right to access, update, or delete your personal information at any time.</li>
        <li>2. You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.</li>
    </ul>
    
    <h2>Changes to this Privacy Policy</h2>
    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be immediately effective upon posting on our website or mobile application.</p>
    
    <h2>Contact Us</h2>
    <p>If you have any queries or concerns regarding this Privacy Policy or our data practices, please reach out to us at <a href="mailto:cabsvelan@gmail.com">contact@velancabs.com</a>.</p>
    
    <p>This Privacy Policy is effective as of 15th May 2024 and applies to all users of Velan Cabs services. By using our website or mobile application, you consent to the terms outlined in this Privacy Policy.</p>


            </ol>
        </div>
    );
}

export default Privacy;
