import React, { useState } from 'react'
import '../assets/custom/css/booking.css'
import MainLayout from '../layout/mainLayout'
import { Col, Row, Form, Table, Button } from 'react-bootstrap'
const Booking = () => {
    const [bookName, setBookName] = useState('');
    const [bookEmail, setBookEmail] = useState('');
    const [bookMobile, setBookMobile] = useState('');
    const [pickupAddress, setPickupAddress] = useState('');
    const [pickupTime, setPickupTime] = useState('');

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};
        if (bookName.trim().length === 0) {
            newErrors.bookName = '*Please enter a Name';
        }
        if (bookEmail.trim().length === 0) {
            newErrors.bookEmail = '*Please enter a Email address';
        }
        if (bookMobile.trim().length === 0) {
            newErrors.bookMobile = '*Please enter a Mobile Number';
        }
        if (pickupAddress.trim().length === 0) {
            newErrors.pickupAddress = '*Please enter a Pickup Address';
        }
        if (pickupTime.trim().length === 0) {
            newErrors.pickupTime = '*Please enter a Pickup Time';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const clearError = (fieldName) => {
        setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));
    };
    const SubmitCall = () => {
        // console.log("cliicc");
        const isFormValid = validateForm();
        try {
            if (isFormValid) {
                // console.log("valid");
            } else {
                // console.log("not valid");
            }
        } catch (e) {
            // console.log(e);
        }
    }
    return (
        <>
            <MainLayout>
                <Row>
                    <Col lg='12' md='12' sm='12' className='BK-bg'>
                        <div className="col-sm-12 welcome-area3">
                            <div className="overlay"></div>
                            <div className="container-fluid">
                                <div className="row row-we1">
                                    <div className="col-md-6">
                                        <div className="welcome-text" style={{ marginTop: '10%' }} >
                                            <h1 >
                                                <span>
                                                    Select Type of
                                                </span>
                                                <br />
                                                Ride
                                                <br />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container my-5 '>
                            <Row className='container'>
                                <Col lg='4'>
                                    <div className='container BK-container-1'>
                                        <div className='container-fluid'>
                                            <div className='mt-2'>
                                                <h4>Booking Summary</h4>
                                            </div>
                                            <div className="table-responsive border-bottom my-3">
                                                <Table
                                                    responsive
                                                    className="table-bordered"
                                                    data-toggle="data-table"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td>Journey Type:</td>
                                                            <th scope="row">oneway</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Cab Type:</td>
                                                            <th scope="row">Indica / Vista / Ritz / Swift or Similar</th>
                                                        </tr>
                                                        <tr>
                                                            <td>From:</td>
                                                            <th scope="row">Chennai Tamil Nadu</th>
                                                        </tr>
                                                        <tr>
                                                            <td>To:</td>
                                                            <th scope="row">Coimbatore Tamil Nadu</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Pickup Date:</td>
                                                            <th scope="row">16-09-2023</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Fare( Inculding Driver Beta):</td>
                                                            <th scope="row">7431</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Tax(5%):</td>
                                                            <th scope="row">372</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Fare:</td>
                                                            <th scope="row">7803</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Distance:</td>
                                                            <th scope="row">506 kms</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Distance:</td>
                                                            <th scope="row">Rs. 12/KMs</th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='8' md='8' sm='8'>
                                    <div className='container BK-container-2'>
                                        <div className='container-fluid'>
                                            <div className='mt-2'>
                                                <h4>Make your booking</h4>
                                            </div>
                                            <Form className='my-3' >
                                                <div className='row mb-3 '>
                                                    <div className='col-6'>
                                                        <Form.Label>Name<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                setBookName(e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase())
                                                                clearError('bookName')
                                                            }}
                                                            value={bookName} />
                                                        {errors.bookName && <div className="error-message">{errors.bookName}</div>}
                                                    </div>
                                                    <div className=' col-6 '>
                                                        <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                setBookEmail(e.target.value)
                                                                clearError('bookEmail')
                                                            }} value={bookEmail}
                                                        />
                                                        {errors.bookEmail && <div className="error-message">{errors.bookEmail}</div>}
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-6'>
                                                        <Form.Label>Mobile<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                setBookMobile(e.target.value.replace(/[^\d.]/g, ''))
                                                                clearError('bookMobile')
                                                            }} value={bookMobile} />
                                                        {errors.bookMobile && <div className="error-message">{errors.bookMobile}</div>}
                                                    </div>
                                                    <div className=' col-6 '>
                                                        <Form.Label>Pickup Address<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                setPickupAddress(e.target.value)
                                                                clearError('pickupAddress')
                                                            }} value={pickupAddress} />
                                                        {errors.pickupAddress && <div className="error-message">{errors.pickupAddress}</div>}
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-6'>
                                                        <Form.Label>Pickup Time<span className='text-danger'>*</span></Form.Label>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                setPickupTime(e.target.value)
                                                                clearError('pickupTime')
                                                            }} value={pickupTime} />
                                                        {errors.pickupTime && <div className="error-message">{errors.pickupTime}</div>}
                                                    </div>
                                                    <div className=' col-6 '>
                                                        <Form.Label>Have Coupon?</Form.Label>
                                                        <Form.Control type="text" />
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    < Form.Label>Choose Payment Method</Form.Label>
                                                    <div className='col-6'>
                                                        <div className='BK-radio'>
                                                            <div className='Bk-radio-inner'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div >
                                                                        <Form.Check.Input type="radio" name="1rdb" className="me-2" />
                                                                        <Form.Check.Label>Pay To Driver</Form.Check.Label>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Check.Input type="radio" name="1rdb" className="me-2" />
                                                                        <Form.Check.Label>Pay 15% advance</Form.Check.Label>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div >
                                                                        <Form.Check.Input type="radio" name="1rdb" className="me-2" />
                                                                        <Form.Check.Label>Pay Full Amount</Form.Check.Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-12'>
                                                        <div >
                                                            <Button class="btn btn-primary" onClick={() => { SubmitCall() }} >Submit form</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-12'>
                                                        <div >
                                                            <Form.Check.Input type="checkbox" name="1rdb" className="me-2" />
                                                            <Form.Check.Label>I understand and agree with the Terms of Service and Cancellation</Form.Check.Label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-12'>
                                                        <div >
                                                            <li> No Driver Batta </li>
                                                            <li> Excluding - Hills, Tollgate, Permit Charges Applicable </li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-3 '>
                                                    <div className='col-12'>
                                                        <p>
                                                            Please Check the terms and condition: Our customer representative will check the vehicle availability and contact you for current (Today/Tomorrow). For advance booking driver details will share
                                                            one day before your travel date Thank You For Choosing Taximo For any futher queries check our website: Velan Cabs
                                                        </p>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </MainLayout>
        </>
    )
}
export default Booking