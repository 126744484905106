import React, { useState, useEffect } from 'react'
import MainLayout from './layout/mainLayout';
import useStateRef from 'react-usestateref';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom'
import './assets/custom/css/app.css'
import appico from './assets/images/icons/appLogo-_1_.png'
import phclicon from './assets/images/icons/phone-call.png'
import myusericon from './assets/images/icons/my-user.png'
import whatsapp from './assets/images/icons/WhatsApp.png.webp'
import Popup from './pages/popup';
import pickupic from './assets/images/customimg/icons8-map-pin-100.png'
import destinationic from './assets/images/customimg/icons8-destination-65.png'
import taxiic from './assets/images/customimg/icons8-taxicab-96.png'
import boardic from './assets/images/customimg/icons8-traveler-100.png'
import enjic from './assets/images/customimg/icons8-taxi-96.png'
import payment1 from './assets/images/customimg/icons8-payment-100.png'
import appstore from './assets/images/customimg/appstore.png'
import playstore from './assets/images/customimg/playstore.png'
import booking from './assets/images/customimg/velan.png'
import ReactCustomModal from './pages/trippopup';
import ReactCustomModal1 from './pages/package';
// import Swiper from "swiper";
// import "swiper/swiper-bundle.css"; 

const CustomSwiper = () => {
  useEffect(() => {
    const swiper = new swiper(".swiper", {
      loop: true,
      spaceBetween: 50,
      pagination: {
        el: ".swiper-custom-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class=${className}>${index + 1}</span>`;
        },
      },
      navigation: {
        nextEl: ".swiper-button-custom-next",
        prevEl: ".swiper-button-custom-prev",
      },
    });
    
    // Clean up function
    return () => {
      swiper.destroy(true, true);
    };
  }, []); 
}  




function App() {
  const sendLink = () => {
  };
  const [departureValue, setDepartureValue] = useState('');
  const [destinationValue, setDestinationValue] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [showPopupTrip, setShowPopupTrip] = useState(false);
  const [showPopupTripPackage, setShowPopupTripPackage] = useState(false);




  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleDepartureChange = (e) => {
    setDepartureValue(e.target.value);
    clearError('departureValue')
  };
  const handleDestinationChange = (e) => {
    setDestinationValue(e.target.value);
    clearError('destinationValue')
  };

  useEffect(() => {
    // Check if the form data exists in local storage
    const formData = localStorage.getItem('offerFormData');
    if (formData) {
      // Data exists, you can choose to hide the popup
       setShowPopup(false);
       setShowPopupTrip(false);
       setShowPopupTripPackage(false);

       
    }
  }, []);


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClosePopupTrip = () => {
    setShowPopupTrip(false);
    setShowPopupTripPackage(false);
  };


  const Playstore = () => {
    window.open('https://play.google.com/store/search?q=velan%20%20cabs&c=apps&hl=en&gl=US', '_blank');
  }
  const PlaystoreDriver = () => {
    window.open('https://play.google.com/store/apps/details?id=com.velan.driver', '_blank');
  }
  const Whatsapp = () => {
    window.open('https://api.whatsapp.com/send?phone=9629562666', '_blank');
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log(date);
    clearError('selectedDate');
  };
  const validateForm = () => {
    const newErrors = {};
    if (departureValue.trim().length === 0) {
      newErrors.departureValue = '*Please select a departure value';
    }
    if (destinationValue.trim().length === 0) {
      newErrors.destinationValue = '*Please select a destination value';
    }
    if (selectedDate === null) {
      newErrors.selectedDate = '*Please select a pickup date.'
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));
  };

  const openTripPopup = () => {
    setShowPopupTrip(true);
    setShowPopup(false);
  }
  const openpackagePopup = () => {
    setShowPopupTripPackage(true);
    setShowPopup(false);
  }

  const SubmitCall = () => {
    // console.log("cliicc");
   const isFormValid = validateForm();
    try {
      if (isFormValid) {
        navigate("/cab")
        //console.log("valid");
      } else {
        // console.log("not valid");
      }
    } catch (e) {
      // console.log(e);
    }
  }
  return (
    <>
      <MainLayout >
        {/* {showPopup && <Popup onClose={handleClosePopup} />} */}

        {showPopupTrip && <ReactCustomModal onClose={handleClosePopupTrip} />}
        {showPopupTripPackage && <ReactCustomModal1 onClose={handleClosePopupTrip} />}


        <div className="top-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <marquee direction='left' >
                  <div className='d-flex'>
                  <div className="icon direction">
                      <img src={appico} alt="applogo" height='28px' />
                    </div>
                    <p className='text-white'>
                      *For Booking Whatsapp us*.....    We provide return trip also along with one way if needed. We are the leading outdoor taxi service in Coimbatore by providing the best service at the lowest fare. For any queries, you may contact our customer service.
                    </p>
                    <div className="icon direction">
                      <img src={appico} alt="applogo" height='28px' />
                    </div>
                  </div>
                </marquee>
              </div>
              <div className="col-md-3 top-menu">
                <ul className="no-bottom-margin">
                  <li><a href="tel:+918072298288"> <img src={phclicon} width="18" alt="phone" /> +91 80722 98288</a></li>
                  <li><a href="tel:+919629562666"> <img src={phclicon} width="18" alt="phone" /> +91 96295 62666</a></li>
                  {/* <li><a href='#'> <img src={myusericon} alt="" width="18" /> Sign In</a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div >
        {/* firdt section */}
        <section className="welcome-area">
          <div className="overlay2"></div>
          <div className="overlay3"></div>
          <div className="overlay1"></div>
          <div className="container-fluid">
            <div className="row row-we1">
              <div className="col-md-6">
                <div className="welcome-text" style={{ paddingTop: '10%' }} >
                  <h1>
                    <span className='mobile_view_Book'>
                      Book your ride now
                    </span>
                    <br />
                    <span className='mobile_view1_Book'>
                      Velan cabs

                    </span>
                    <br />
                    </h1>
                    <div>
                      <a href="#" title="">
                        <button type="submit" className="btn btn-main bg-btn block">
                          <span class="circle"></span><span className='text-white' onClick={() => { Whatsapp() }}>
                            <img style={{ marginTop: '-1px', marginRight: '4px' }} src={whatsapp} alt="" width="33" />
                            Book Now


                          </span>
                        </button>
                      </a>
                      <ul className="app-ul">
                    <li> <img onClick={() => { Playstore() }} src={playstore} alt="Playstore" /> </li>
                    {/* <li> <img src={appstore} alt="Appstore" /></li> */}
                  </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-ride-main pt40 pb20">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="common-heading col-lg-12 text-center pb-2">
                  <h1 className='pb-1'>Your nearest taxi is now on Velan Cabs</h1>
                  <p >
                    Introducing cabs service, the newest way to get a ride with Velan Cabs.
                    Now you have the option to request a taxi using your Velan Cabs app.
                  </p>
                </div>
                <div className='container'>
                  <section className="wh-work pt40 pb60">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={pickupic} alt="" />
                            </div>
                            <h3>Pickup location</h3>
                            <p className="text-justify">
                              Once you've downloaded Velan Cabs app,
                              open it up and allow the app to access your device's location.
                              Alternatively, you can manually enter your pickup location in the app
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom ">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={destinationic} alt="Where To Go" />
                            </div>
                            <h3>Destination</h3>
                            <p className="text-justify">
                              Enter the address or name of your destination in the app.
                              The app will calculate and display the estimated fare and the time it will take for a driver to reach your pickup location.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={taxiic} alt="Pickup Date" />
                            </div>
                            <h3>Select type ride</h3>
                            <p className="text-justify">
                              Choose the type of vehicle you want to ride, such as economy, premium, or SUV,
                              depending on the options available in the app
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid pt-5">
                      <div className="row ">
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={boardic} alt="Choose Cab" />
                            </div>
                            <h3>Board the taxi</h3>
                            <p className="text-justify">
                              Once your driver arrives at the pickup location, you will receive a notification.
                              Double-check the driver's identity by verifying their name and vehicle details before boarding the taxi.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom ">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={enjic} alt="Where To Go" />
                            </div>
                            <h3>Enjoy your ride</h3>
                            <p className="text-justify">
                              Sit back, relax, and enjoy your ride to your destination.
                              The app will show the estimated time of arrival at your destination.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 col-6 zoom">
                          <div className="work-box text-center">
                            <div className="wwb-img">
                              <img src={payment1} alt="Pickup Date" />
                            </div>
                            <h3>Payment</h3>
                            <p className="text-justify">
                              After reaching your destination,
                              the app will automatically charge the fare to your connected payment method.
                              You can choose to add a tip for the driver if you wish
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="welcome-area2 welcome-area123" id='welcome' >
          <div className="layer_blure">
          <div className="overlay"></div>
          <div className="container-fluid" style={{ height: "100%"}}>
            <div className="row row-we1" style={{height: "100%" }}>
              <div className="col-md-6" style={{margin: "auto"  }}>
                <div className="welcome-text" >
                  <h1 className='.sec-color-1' style={{ fontSize: '6rem', color: 'white' }}>
                    <span>
                      Your <span className='sec-color'> Safety </span> is our<br /> priority
                    </span>
                  </h1>
                </div>
              </div>
              <div className="col-md-6" style={{margin: "auto" }}>
                <div className="welcome-right">
                  <div className="contact-box-main searchBox">
                    <div className="contact-box">
                      <div className='text-center'>
                      <nav className="navbar navbar-expand-lg theme-nav fixed-top" style={{ alignItems: 'center' }}>
                      <div>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><a >One Way</a></li>
            <li className="nav-item"><a onClick={() => { openTripPopup() }}>Round Trip</a></li>
            <li className="nav-item"><a onClick={() => { openpackagePopup() }}>Package</a></li>
          </ul>
        </div>
        </nav>        
                        {/* <h3 style={{ paddingBottom: '10px' }}>
                          One Way Taxi
                        </h3> */}
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane active">
                          <form noValidate>
                            <div className="form-group">
                              <TextField label="Pickup"
                                variant="outlined"
                                fullWidth
                                onChange={handleDepartureChange}
                                name="departure_city"
                                value={departureValue}
                                placeholder='Pickup Location' />
                              {errors.departureValue && <div className="error-message">{errors.departureValue}</div>}
                            </div>
                            <div className="form-group">
                              <TextField label="Destination"
                                variant="outlined"
                                fullWidth
                                name="departure_city"
                                onChange={handleDestinationChange}
                                value={destinationValue}
                                placeholder='Destination Location' />
                              {errors.destinationValue && <div className="error-message " >{errors.destinationValue}</div>}
                            </div>
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Pickup Date"
                                  value={selectedDate} // Use selectedDate or defaultDate as the value
                                  onChange={handleDateChange}
                                  slotProps={{ textField: { fullWidth: true } }} />
                              </LocalizationProvider>
                              {errors.selectedDate && <div className="error-message">{errors.selectedDate}</div>}
                            </div>
                            <div className="form-group text-center btn-form--group mb-0">
                              <Button variant="contained"
                                className="btn btn-main bg-btn block"
                                onClick={() => { SubmitCall() }}
                              >
                                Search  Cabs
                                <span className="circle"></span>
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        {/* appstore sectiom */}
        <section className="app-main pt60">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 work-col-md-6">
                <div className="abt-i-con os-left wg-con">
                  <h3>Velan Cabs <span className="sec-color">from PlayStore </span></h3>
                 
                  <ul className="app-ul">
                    <li> <img onClick={() => { Playstore() }} src={playstore} alt="Playstore" /> </li>
                    {/* <li> <img src={appstore} alt="Appstore" /></li> */}
                  </ul>
                  {/* <p>Download the app for driver / Enter Your Mobile Number. We'll send you the link of our app</p> */}
                  {/* <p><h4>For Driver Attachment Download Velan Driver App From PlayStore<br>For </br></h4></p> */}

    <p>For Driver Attachment download the Velan Driver app Or  <br /><b>Contact us @ +91 63790 32541</b>   </p>
  {/* <a href="https://play.google.com/store/apps/details?id=com.velan.driver" target="_blank"> */}
    {/* Download Velan Driver App */}
  {/* </a> */}
                  <ul className="app-ul">
                    <li> <img onClick={() => { PlaystoreDriver() }} src={playstore} alt="PlaystoreDriver" /> </li>
                    {/* <li> <img src={appstore} alt="Appstore" /></li> */}
                  </ul>
                </div>
                <br />
                {/* <table className='mobile_view1'>
                  <tbody>
                    <tr>
                      <td>
                        <input type="text" className="form-control form-rounded-ftr-1" value="+91" placeholder="+91" disabled="" />
                      </td>
                      <td>
                        <input type="text" name="mob_number" id="mob_number" className="form-control form-rounded-ftr-2" placeholder="Mobile Number" />
                      </td>
                      <td>
                        <input type="button" className="form-control form-rounded-ftr-3" placeholder="Send me the link" value="Send me the link" onClick={sendLink} />
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <div className="col-md-6">
                <div className="text-center mobile_view1">
                  <img src={booking} width="500" alt="Booking" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout >
    </>
  );
}
export default App;





























