import React from 'react'
import '../assets/custom/css/FooterBar.css'
import fbicon1 from '../assets/images/brands/08.png'
import twicon1 from '../assets/images/brands/09.jpg'
import igicon1 from '../assets/images/brands/10.png'
import google1 from '../assets/images/brands/11.png'
import droppin1 from '../assets/images/icons/icons8-location-100.png'
import gpay from '../assets/images/brands/icons8-google-pay-is-the-fast,-simple-way-to-pay-online,-in-stores-and-more.-48.png'
import bhim from '../assets/images/brands/icons8-bhim-upi-100.png'
import visa from '../assets/images/brands/icons8-visa-100.png'
import mastercard from '../assets/images/brands/icons8-mastercard-100.png'
import applogopng from '../assets/images/customimg/appLogo.png'
import calllogo from '../assets/images/brands/icons8-call-90.png'
import { useNavigate } from 'react-router-dom'
import gmail from '../assets/images/brands/icons8-gmail-100.png'
function FooterBar() {
    const navigate = useNavigate();
    const Whatsapp = () => {
        window.open('https://api.whatsapp.com/send?phone=9629562666', '_blank');
    }

    const Avelator = () => {
        window.open('http://www.avelator.com/');
    }

    const PrivacyPolicy = () => {
        try {
            navigate("/privacy") 
        } catch (error) {
            console.log("error",error);
        }    
      }

      const Termsandconditons = () => {
        try {
            navigate("/Termsandconditons") 
        } catch (error) {
            console.log("error",error);
        }    
      }
    return (
        <footer className="footer pt60" >
            <div className="container-fluid" id='container-fluid'>
                <div className="row footer-row">
                    <div className="row">
                        <div className="col-md-12 work-col-md-3">
                            <div className="d-flex-1">
                                <div className="abt-i-con ">
                                    <span className="abt-i-span"> Velan Cabs
                                        <img style={{ height: '50px' }} src={applogopng}></img>
                                    </span>
                                </div>
                                <div className="abt-i-con">
                                    <span className="abt-i-span">
                                        <img style={{ height: '20px' }} src={droppin1}></img> coimbatore
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footerlistbox">
                                <br />
                                <h4 className="low-cas">CONTACT INFO</h4>
                                <p onClick={() => { Whatsapp() }} ><img style={{ height: "20px" }} src={calllogo} />&nbsp;
                                    +91 96295 62666 </p>
                                <p><img style={{ height: "20px" }} src={gmail} />&nbsp;
                                cabsvelan@gmail.com</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footerlistbox">
                                <h4 className="low-cas">Social Media</h4>
                                <div className="fsm-icons mt20">
                                    <a href="https://www.facebook.com/profile.php?id=61554674335339&mibextid=ZbWKwL"><i > <img src={fbicon1} alt="" /></i></a>
                                    <a href="https://www.instagram.com/velan_cabs/?utm_source=qr&igshid=MThlNWY1MzQwNA%3D%3D"><i > <img src={igicon1} alt="" /></i></a>
                                    <a href="https://twitter.com/Velan_cabs?t=MnXar9g8-DEr9ykKEDzmvw&s=09"><i > <img src={twicon1} alt="" /></i></a>
                                    <a href="https://velancabs.com/"><i > <img src={google1} alt="" /></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footerlistbox">
                                <h4 className="low-cas">Payment Methods</h4>
                                <div className="fsm-icons mt20">
                                    <a href="#"><i > <img style={{ height: '40px' }} src={gpay} alt="" /></i></a>
                                    <a href="#"><i > <img style={{ height: '40px' }} src={bhim} alt="" /></i></a>
                                    <a href="#"><i > <img style={{ height: '30px' }} src={mastercard} alt="" /></i></a>
                                    <a href="#"><i > <img style={{ height: '40px' }} src={visa} alt="" /></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footerlistbox mt20">
                                <h4 className="low-cas">Company Policies</h4>
                                <ul className="footerlist mt20">
                                    <li ><a className='havor' onClick={() => { Termsandconditons() }}> Terms &amp; Conditions </a></li>
                                    <li><a className='havor'   onClick={() => { PrivacyPolicy() }}  >Privacy Policy</a></li>
                                    {/* <li><a href="#">Contact Us </a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
                <div className="footer-btm">
                    <div className="container">
                        <div className='row'>

                            <div class="copyright-box"><center>Copyright &copy;
                                2024 <span className='cab'>Velan Cabs</span>. All Right Reserved</center>
                            </div>    

                             <div class="copyright-box"><center>Powered By 
                                 <span className='cab'>  Avelator Solutions India Private Limited</span></center>
                            </div>    
                        </div>
                    </div>
                </div>
        </footer>
    )
}
export default FooterBar