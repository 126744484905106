import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/custom/css/horizontalNavBar.css';
import applogo from '../assets/images/customimg/velanlogo.png';

function HorizontalNavBar() {
  const navigate = useNavigate();

  const bookingCall = () => {
    // navigate("/offer")
  }

  const carDetails = () => {
    navigate("/cab")
  }

  const homeDetails = () => {
    navigate("/home")
  }

  const booknow = () => {
    navigate("/cab")
  }
  const scrollToContact = () => {
    // const contactSection = document.getElementById("contactcontainer-fluid");
    const contactSection = document.getElementById("container-fluid");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const scrollTowelcometext = () => {
    const serviceSection = document.getElementById("welcome");
    if (serviceSection) {
      serviceSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <nav className="navbar navbar-expand-lg theme-nav fixed-top mobile_view1" style={{ height: '50px', alignItems: 'center' }}>
      <div className="container-fluid">
        <div className="logo">
          <a className="navbar-brand " href="/">
            <img className="reg-logo" src={applogo} alt="applogo" width="160" />
          </a>
        </div>
        <div>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><a className="nav-link" onClick={homeDetails} >Home</a></li>
            {/* <li className="nav-item"><a className="nav-link" onClick={bookingCall}>Booking</a></li> */}
            <li className="nav-item"><a className="nav-link" onClick={carDetails}>Ride Facilities</a></li>
            <li className="nav-item"><a className="nav-link" onClick={()=>scrollTowelcometext()}>Support</a></li>
            <li className="nav-item"><a className="nav-link" onClick={()=>scrollToContact()}>ContactUs</a></li>
          </ul>
        </div>
        <div>
          <div className="nav-item_1">
            <a className="nav-link" onClick={booknow}>
              <button type="submit" className="btn btn-main-Button bg-btn block">
                <span className="circle"></span><span className='textbook'>Book Now </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HorizontalNavBar;
