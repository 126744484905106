// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BK-bg {
    background-color: white;
  }
  
  .BK-bg .BK-container-1 {
    padding: 5px 0px 0.5px 0px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 31%)
  }
  
  .BK-bg .BK-container-2 {
    padding: 5px 0px 0.5px 0px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 31%)
  }
  
  .BK-radio {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
  }
  
  .BK-radio .Bk-radio-inner {
    margin: 1px 1px;
  }`, "",{"version":3,"sources":["webpack://./src/assets/custom/css/booking.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;EACzB;;EAEA;IACE,0BAA0B;IAC1B,sBAAsB;IACtB;EACF;;EAEA;IACE,0BAA0B;IAC1B,sBAAsB;IACtB;EACF;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".BK-bg {\n    background-color: white;\n  }\n  \n  .BK-bg .BK-container-1 {\n    padding: 5px 0px 0.5px 0px;\n    background-color: #fff;\n    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 31%)\n  }\n  \n  .BK-bg .BK-container-2 {\n    padding: 5px 0px 0.5px 0px;\n    background-color: #fff;\n    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 31%)\n  }\n  \n  .BK-radio {\n    border: 1px solid #d1d1d1;\n    border-radius: 5px;\n  }\n  \n  .BK-radio .Bk-radio-inner {\n    margin: 1px 1px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
