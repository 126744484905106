import React, { useState, useEffect } from 'react';
import '../assets/custom/css/package.css';
const ReactCustomModal1 = ({ onClose, offerData }) => {




    return (
      <div className="popup">
        <div className="popup-content-1">

        {/* <button className="popup-close-1" style={{ marginTop: '-6px' }} onClick={onClose}> */}
            <h4 onClick={onClose} className="cancel_icon">X</h4>
          {/* </button> */}
          <h1 className='text_center'>Package</h1>
    <h3 className='popup_content_center'>Best Packages Available At Minimum Cost For Contact More Details <br /><br/>
    <h4><span className='popup_content_span'>&#9743;</span>+91 96295 62666 <br /><span className='popup_content_span'>&#9743;</span>+91 80722 98288</h4></h3>
        </div>
      </div>
    );


};

export default ReactCustomModal1;



