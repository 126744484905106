import React, { Fragment } from 'react'
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'

import HorizontalNavBar from '../component/horizontalNavBar'
import FooterBar from '../component/FooterBar'

function mainLayout({ children }) {
    return (
        <Fragment>
            <section className='wrapper'>
                <HorizontalNavBar />
                <div>{children}</div>
                <FooterBar />
            </section>
        </Fragment>
    )
}

export default mainLayout