import React, { useState } from 'react'
import '../assets/custom/css/booking.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MainLayout from '../layout/mainLayout'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom'

import { Col, Row, Form, Table, Button } from 'react-bootstrap'

const Offer = () => {
    const navigate = useNavigate();

    const [offertittle, setOffertittle] = useState('');
    const [offerprecentage, setOfferprecentage] = useState('');
    const [pickuplocation, setPickuplocation] = useState('');
    const [droplocation, setDroplocation] = useState('');
    const [todate, setTodate] = useState(null);
    const [fromdate, setFromDate] = useState(null);

    const handleDateChange = (date) => {
        setFromDate(date);
        // console.log(date);
    };
    const handleDateChange1 = (date) => {
        setTodate(date);
    };

    const SubmitCall = () => {
        const currentDate = new Date().toISOString();

        try {
            const users = {
                "offer_tittle": offertittle,
                "offer_precentage": offerprecentage,
                "pickup_location": pickuplocation,
                "drop_location": droplocation,
                "from_date": fromdate,
                "to_date": todate,
                "created_at": currentDate
            };
            // fetch("https://gateway.velancabs.com/admin/offer", {
                  fetch("http://192.168.0.114:8000/admin/offer", {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(users),
            })
                .then(response => response.json())
                .then(async data => {
                    console.log(data);
                    navigate("/")
                })
        } catch (error) {
            console.error('Error :', error);
        }
    };
    return (
        <>
            <MainLayout>
                <Row>
                    <Col lg='12' md='12' sm='12' className='BK-bg'>
                        <div className="col-sm-12 welcome-area3">
                            <div className="overlay"></div>
                            <div className="container-fluid">
                                <div className="row row-we1">
                                    <div className="col-md-6">
                                        <div className="welcome-text" style={{ marginTop: '10%' }} >
                                            <h1 >
                                                <span>
                                                    Select Type of
                                                </span>
                                                <br />
                                                Offer
                                                <br />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container my-5 '>
                            <Row className='container'>
                                <div className='container BK-container-2'>
                                    <div className='container-fluid'>
                                        <div className='mt-2'>
                                            <h4>Make your Offer</h4>
                                        </div>
                                        <Form className='my-3' >
                                            <div className='row mb-3 '>
                                                <div className='col-6'>
                                                    <Form.Label>Offer Tittle<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text"
                                                        onChange={(e) => {
                                                            setOffertittle(e.target.value)
                                                        }}
                                                        value={offertittle}
                                                        required />
                                                </div>
                                                <div className='col-6'>
                                                    <Form.Label>Offer Percentage<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        inputMode="numeric"
                                                        pattern="^[0-9]*$"
                                                        onChange={(e) => {
                                                            setOfferprecentage(e.target.value);
                                                        }}
                                                        value={offerprecentage}
                                                        required
                                                    />
                                                    {/* <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback> */}
                                                </div>
                                            </div>
                                            <div className='row mb-3 '>
                                                <div className='col-6'>
                                                    <Form.Label>From Pickup Location<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text"
                                                        onChange={(e) => {
                                                            setPickuplocation(e.target.value)
                                                        }} value={pickuplocation}
                                                        required />
                                                </div>
                                                <div className=' col-6 '>
                                                    <Form.Label>From Drop Location<span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text"
                                                        onChange={(e) => {
                                                            setDroplocation(e.target.value)
                                                        }} value={droplocation}
                                                        required />
                                                </div>
                                            </div>
                                            <div className='row mb-3 '>
                                                <div className='col-6'>
                                                    <Form.Label>From Date<span className='text-danger'>*</span></Form.Label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            //   label="Pickup Date"
                                                            value={fromdate} // Use selectedDate or defaultDate as the value
                                                            onChange={handleDateChange}
                                                            slotProps={{ textField: { fullWidth: true } }} />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className=' col-6 '>
                                                    <Form.Label>To Date</Form.Label>
                                                    <div className="form-group">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                //   label="Pickup Date"
                                                                value={todate} // Use selectedDate or defaultDate as the value
                                                                onChange={handleDateChange1}
                                                                slotProps={{ textField: { fullWidth: true } }} />
                                                        </LocalizationProvider>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3 '>
                                                <div className='col-12'>
                                                    <div >
                                                        <Button class="btn btn-primary" onClick={() => { SubmitCall() }} >Submit form</Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </MainLayout>
        </>
    )
}
export default Offer