import React from 'react';
import '../assets/custom/css/FooterBar.css';
import back1 from '../assets/images/back.png'
import { useNavigate } from 'react-router-dom'
function Termsandconditons() {
    const navigate = useNavigate();

    const back = () => {

        try {

            navigate("/")
            //console.log("valid");

        } catch (e) {

        }
    }

    return (
        <div style={{ padding: "50px" }}>
            <a onClick={() => { back() }} ><i > <img style={{ height: '50px' }} src={back1} alt="" /></i></a>
            <h1 style={{ display: "flex", justifyContent: "center", marginTop: "-50px" }} >Terms and Conditions</h1>
            <h3>
                <br/>

                {/*            
           <span style={{marginLeft:"20px"}}>
           At Velan Cabs, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to safeguard it.
            </span>   */}
            </h3>



            <p>Service Agreement: By using Velan Cabs' services, you agree to abide by the terms and conditions outlined herein.</p>
            <h2>Booking and Payments:</h2>
            <ul>
                <li>Users must provide accurate information when booking a cab.</li>
                <li>Payment must be made in accordance with the specified rates and methods.</li>
                <li>Velan Cabs reserves the right to modify fares and payment methods at any time.</li>
            </ul>
            <h2>Cancellation Policy:</h2>
            <ul>
                <li>Users may cancel bookings within the specified time frame to avoid cancellation charges.</li>
                <li>Velan Cabs may cancel bookings under certain circumstances and will notify users accordingly.</li>
            </ul>
            <h2>User Responsibilities:</h2>
            <ul>
                <li>Users must treat drivers and other passengers with respect.</li>
                <li>Users are responsible for their behavior during the journey and must refrain from any unlawful activities.</li>
            </ul>
            <h2>Driver Conduct:</h2>
            <ul>
                <li>Velan Cabs ensures that drivers meet certain standards of professionalism and safety.</li>
                <li>Users can report any issues with driver conduct to Velan Cabs for investigation.</li>
            </ul>
            <h2>Safety and Security:</h2>
            <ul>
                <li>Velan Cabs prioritizes the safety and security of its users.</li>
                <li>Users are encouraged to report any safety concerns during the journey.</li>
            </ul>
            <h2>Liability:</h2>
            <ul>
                <li>Velan Cabs is not liable for any loss, damage, or injury incurred during the use of its services, except where mandated by law.</li>
            </ul>
            <h2>Privacy Policy:</h2>
            <ul>
                <li>Velan Cabs respects user privacy and handles personal data in accordance with its privacy policy.</li>
            </ul>
            <h2>Governing Law:</h2>
            <ul>
                <li>These terms and conditions are governed by the laws of the jurisdiction in which Velan Cabs operates.</li>
            </ul>
            <h2>Modification of Terms:</h2>
            <ul>
                <li>Velan Cabs reserves the right to modify these terms and conditions at any time. Users will be notified of any changes.</li>
            </ul>
            <p>By using Velan Cabs' services, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>

        </div>
    );
}

export default Termsandconditons;
