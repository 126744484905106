import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import CabsSearch from './pages/cabsSearch';
import Booking from './pages/booking';
import HorizontalNavBar from './component/horizontalNavBar';
import Offer from './pages/offer';
import Privacy from './component/privacy';
import Termsandconditons from './component/termsandconditions';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "cab",
    element: <CabsSearch />
  },
  {
    path: "booking",
    element: <Booking />
  },
  {
    path: "offer",
    element: <Offer />
  },
  {
    path: "home",
    element: <App />
  },
    {
    path: "privacy",
    element: <Privacy />
  },
  {
    path: "Termsandconditons",
    element: <Termsandconditons />
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




